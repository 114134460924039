/* .archive-link {
    color: theme('colors.grey-faded');
    font-size: 0.9em;
    vertical-align:  middle;
    font-style: italic;
} */
  
.arrow {
    font-size: 3.8em;
    top: 1%;
    left: 0.5%;
    position: fixed;
}

.arrow a {
    text-decoration: none;
}


/* FOOTER */
.back-to-top {
    position: fixed;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    padding: 0.3% 1%;
    background: #d6d6d6e5;
    border-radius: 20px;
    z-index: 10;
    text-align: center;
}

body {
    background-color: theme('colors.grey');
    /* font-size: 1.15em; */
    margin: 5% 5% 5% 5%;
}

.claim-paragraph {
    margin-top: 4%;
}


button {
    padding: 10px;
    margin: 5px;
    cursor: crosshair;
    border: dotted black;
    border-radius: 5px;
    background-color: transparent;
    font-size: 0.8em;
}

.how-to {
    margin-top: 4%;
    width: 55%;
    margin-left: 25%;
}

/* DETAILS */
li.dotpoint {
    margin-left: 0.9rem;
}

/* SOURCE LINKS */
li span {
    position: relative;
    left: 2%;
    top: 0;
}

.icon-link li {
    content: '';
    /* background: url('/public/img/circle-grey-dark.png') no-repeat center center; */
    list-style-image: url('/public/img/circle-grey-dark.png');
    /* background-size: contain; */
    /* display: inline-block; */
    /* width: 0.1rem; */
    /* height: 0.8rem; */
    /* vertical-align: -5%; */
    /* margin-right: 5%; */
    /* list-style: square; */
    margin-left: 1rem;
}

.icon-playarrow li {
    content: '';
    /* background: url('/public/img/play-arrow-grey-dark.svg') no-repeat center center; */
    list-style-image: url('/public/img/play-arrow-grey-dark.svg');
    /* background-size: contain; */
    /* display: inline-block; */
    /* width: 0.6rem; */
    /* height: 0.8rem; */
    /* width: 16px; */
    /* height: 16px; */
    /* margin-right: 3%; */
    /* vertical-align: -5%; */
    margin-left: 1rem;
}

.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.99);
    justify-content: center;
    align-items: center;
    /* z-index: 1000; */
}

.modal-content.video-link {
    background: none;  
    overflow-y: hidden;
    width: 46%;
    height: 100%;
}

.source {
    margin-bottom: 7%;
}

/* for sources that have been deleted by original poster */
strike {
    color: red;
}

/*** CLAIM DROPDOWN FILTER ***/
.claim-filter {
    flex: 1;
}

select.claim-dropdown-box {    
    /* disable default styles */
    appearance: none;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    border: solid 0.5px black;
    padding: 0.5rem 2rem 0.5rem 1rem;
    margin-top: 1.1rem;
    text-overflow: ellipsis;

    /* Firefox styles - remove dotted firefox border */
    /* @-moz-document url-prefix() {
        select {
            color: rgba(0,0,0,0);
            text-shadow: 0 0 0 #ffffff;
        } */
    
    /* border: solid 0.5px black;
    background-color: #f0f0f0;
    cursor: row-resize;
    
    padding: 0.7rem 1.5rem 0.7rem 1rem;
    border-radius: 3px;
    text-overflow: ellipsis; */
        
    /* Custom arrow */
    background-image:
        linear-gradient(45deg, transparent 50%, red 50%),
        linear-gradient(135deg, red 50%, transparent 50%);
        /* linear-gradient(to right, #ccc, #ccc); */
    background-position:
        calc(100% - 20px) calc(1em + 1px),
        calc(100% - 15px) calc(1em + 1px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
}

/* Hide default dropdown arrow */
select.claim-dropdown-box::-ms-expand {
    display: none;
}

.no-results-text {
    margin-left: 25%;
    margin-right: auto;
}


/*** SEARCH BAR ***/
.search-bar-container {
    display: none; /* Hide on desktop for now */
    /* display: flex; */
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.search-bar {
    flex: 1;
    min-width: 15rem;
}

.search-bar input {
    /* height: 1.5rem; */
    font-size: 0.85em;
    font-family: 'Helvetica', Helvetica, sans-serif;
    /* letter-spacing: 0.8px; */
    line-height: 25px;
    background: #ffff;
    border: 0.7px solid black;
    border-radius: 3px;
    padding: 0.5rem 0.5rem 0.4rem 2.4rem;
    margin-top: 1.1rem;

    /* icon */
    background-image: url('/public/img/magnifying-glass.png');
    background-size: 1.3em;
    background-repeat: no-repeat;
    background-position: 0.7rem center;

    text-overflow: ellipsis;
}


/*** 'HOW TO USE THE TRACKER' text (appears when no search results found) ***/
ul.dotted {
    margin-left: -5.8%;
    margin-top: 3%;
}

li.dotted {
    margin-left: 7.5%;
    margin-bottom: 2.5%;
    list-style: disc;
}

li.undotted {
    margin-right: 6%;
}



/*** TRACKER TABLE ***/
.tracker-container {
    margin-top: 5%;
    width: 100%;
    text-wrap: justify;
 }

.table-container {
    top: 40px;
    position: relative;
}

table {
    width: 100%;
    position: relative;
}

td {
    padding: 25px;
    text-align: left;
    vertical-align: top;
}

thead {
    width: 90%;
    text-align: left;
    border-bottom: 1px dashed #595959;
    /* background-color: theme('colors.grey'); */
    z-index: 10;  
    top: 30%;
}

th {
    padding-bottom: 20px;
    /* padding-left: 20px; */
    /* width: 100%; */
    /* white-space: nowrap; */
    /* position: fixed; */
}

table.a {
    color: #345c00;
    font-size: 13.5px;
}

table.a:hover {
    color: whitesmoke;
}

.claim {
    font-style: oblique;
    font-size: 0.9em;
    letter-spacing: 1.5px;
}

.context {
    font-size: 0.9em;
}

.date {
  font-size: 1em;
  font-family: 'Helvetica', Helvetica, sans-serif;
  vertical-align: top;
}

.claim-header-container {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 40;
    opacity: 0.97;
    background: #cbcbcb;
    height: 60px;
    /* box-shadow: 0px 10px 15px -3px #9eff2038, 0px 2px 3px -2px rgba(0, 0, 0, 0.05); */
    top: 0;
}

.claim-tag {
    font-size: 0.95em;
    border: solid 0.5px theme('colors.grey-faded');
    padding: 5% 21% 5% 21%;
    border-radius: 3px;
    background: theme('colors.green-light');
}

.context-tag {
    font-size: 0.95em;
    border: solid 0.5px theme('colors.grey-faded');
    padding: 5% 12% 5% 12%;
    border-radius: 3px;
    background: theme('colors.blue');
}

.debunk-tag {
    font-size: 0.95em;
    border: solid 0.5px theme('colors.grey-faded');
    padding: 6% 13% 6% 13%;
    border-radius: 3px;
    background: theme('colors.green');
}

u {
    text-decoration: underline 0.7px;
}

/* Hidden messages for Text-to-Speech accessibility */
.visually-hidden {
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;
}



/* oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

TABLET VIEW

oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo */
@media only screen and (min-width: 577px) and (max-width: 767px) {
    .logo-container {
        margin-top: 10.5%;
    }

    .search-bar {
        flex: 1;
    }

    .claim-summary {
        padding-bottom: 3%;
        
    }

    thead {
        top: 38%;
    }
}



/* oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

MOBILE VIEW

oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo */
@media only screen and (max-width: 576px) {
    a {
        z-index: 1; /* hide link text under video*/
    }

    .claim-header-container {
        height: 40px;
        /* box-shadow: 0px 10px 15px -3px #9eff2038, 0px 2px 3px -2px rgba(0, 0, 0, 0.05); */
        top: 0;
        border-bottom: #867d7d solid 0.5px;
        padding: 8px;
    }

    /* Hide on mobile. You already see the title in the claim dropdown + search bar */
    .claim {
        display: none;
        /* font-size: 1em;
        letter-spacing: 0;
        border: 0.5px solid black;
        margin-left: 16.3%;
        vertical-align: 0;
        border-radius: 3px;
        background: #cbcbcb;
        width: 43%;
        font-style: normal;
        vertical-align: center;
        text-align: center;
        position: absolute;
        padding: 0.3% 0% 0.3% 0%; */
    }
    
    /* Type tags */
    .claim-tag {
        font-size: 0.95em;
        padding: 0.8% 1% 0% 1%;  
        width: 14%;
        position: absolute;
        left: 30.5%;
        text-align: center;
    }

    .context-tag {
        font-size: 0.95em;
        padding: 0.5% 1.3% 0.25% 1%;  
        width: 17%;
        position: absolute;
        text-align: center;
        left: 30.5%;
    }


    .debunk-tag {
        font-size: 0.9em;
        padding: 0.5% 1% 0.2% 1%;  
        width: 16%;
        position: absolute;
        left: 30.5%;
        text-align: center;
    }

    .date {
        font-size: 0.95em;
        background: theme('colors.grey-darker');
        border: 0.5px solid black;
        border-radius: 3px;
        position: absolute;
        text-align: center;
        left: 5.25%;
        padding-left: 0.5%;
        padding-right: 0.5%;
        padding-bottom: -0.3%;
        width: 23.5%;
        padding: 0.3% 0% 0.3% 0%;
      }

    .data-row {
        margin-top: 8%;
        padding-bottom: 15px;
        border-bottom: 0.1em dashed #59595994;
    }

    .claim-paragraph {
        margin-top: 3%;
    }

    /* Margin spacing above dotted divider/under final source */
    .sources {
        margin-top: 1%;
    }


    /* Specific styles to tracker grid, different to <details> on other pages */
    details[open] {
        cursor: default;
    }

    details {
        margin-top: 15%;
        line-height: 1.45rem;
    }
    
    .how-to {
        font-size: 1em;
        margin-top: 7%;
        margin-left: 1%;
        width: 95%;
    }

    li {
        margin-bottom: -6%;
        margin-left: -4.5%;
        width: 90%;
    }

    li.dotpoint {
        margin-left: 4%;
    }

    /* Claim dropdown filter + Search bar */
    .no-results-text {
        margin-left: 1%;
    }

    /* Background that holds Claim filter dropdown + search bar */
    /* .tools-background {
        background: #dadada;
        padding: 5rem 20rem 3rem 20rem;
        left: 0;
        top: 2%;
        position: fixed;
        z-index: 20;
    } */

    
    /*** CLAIM DROPDOWN FILTER ***/
    select.claim-dropdown-box {   
        position: fixed;
        padding: 0 0.5rem 0 0.5rem;
        /* top: 2rem; */
        left: 4.5%;
        width: 40%;
        padding-right: 6%;
        height: 4.65%;
        z-index: 10;

        /* Red dropdown arrow */
        background-position:
        calc(100% - 15px) calc(1em + 1px),
        calc(100% - 10px) calc(1em + 1px),
        calc(100% - 2.5em) 0.5em;
    }


    /*** SEARCH BAR ***/
    .search-bar-container {
        position: fixed;
        width: 100%;
        top: 3.9%;
    }
    .search-bar {
        position: fixed;
        left: 45%;
        flex: 1;
        width: 53%;
        min-width: 15%;
    }

    .search-bar input {
        padding: 4% 2% 2% 18%;
        flex: 1;
    }


    /*** SOURCE LINKS ***/
    .source {
        font-size: 0.9em;
        margin-bottom: 9%;
        width: 95%;
    }

    .source-heading {
        /* background: #dcdcdc; */
        /* padding: 0.3% 0.9% 0.3% 0.9%; */
        /* border: 0.5px solid black; */
        border-radius: 3px;
        width: 20%;
        margin-top: 6%;
        margin-bottom: 3%;
    }

    .source-heading::after {
        content: 'Source:';
        text-decoration: underline;
    }
    
    .icon-link li {
        margin-right: 60%;
    }

    .icon-playarrow li {
        margin-right: 60%;
    }

    /*** TRACKER TABLE ***/
    .tracker-container {
        width: 100%;
        /* flex: 1; */
        margin-top: 0%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; 
    }

    table {
        border: none;
        width: 300%;  /* allows table to scroll for ages horizontally, on mobile */
        border-collapse: collapse;
    }

    tr {
        margin-bottom: 20px;
        margin-top: 20px;
        border: none;
    }

    tr:hover {
        background-color: red;
        transition: background-color 0.3 ease;
    }

    td {
        border-bottom: none;
        text-align: left;
        padding: 25px;
        margin: 0 -4% 0 -4%;
    }

    thead {
        border: none;
        height: 80px;
        top: 5%;
        left: 1%;
        z-index: 20;
        border-bottom: #867d7d solid 0.5px;
    }

    th {
        text-align: center;
        font-size: 1em;
    }


    /*** FOOTER ***/
    .back-to-top {
        margin-left: -2.25%;
    }
    
    
    /* TODO: are these being referenced at all...? */
    .arrow {
        font-size: 1.9em;
        top: 1.5%;
        margin-left: 2%;
    }

    .link-icon::after {
        content: '🗏\00a0';
    }
}


/* TODO: Is this being used? */
    .modal-open {
        overflow: hidden;
    }
