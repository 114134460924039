input {
    /* width: 100%; */
    /* margin-top: 2.5%; */
    /* border-color: #bfc3c8; */
    border-radius: 3px;
    border-style: hidden;
    background-color: theme('colors.grey-darker');
    font-size: 0.9em;
}

textarea {
    /* width: 100%; */
    /* margin-top: 2.5%; */
    border-radius: 3px;
    border-style: hidden;
    background-color: theme('colors.grey-darker');
    font-family: 'Helvetica', Helvetica, sans-serif;
    font-size: 0.9em;
    padding: 1%;
    padding-bottom: 0;
}

select {
    width: 100%;
    margin-top: 3.5%;
    border-radius: 3px;
    font-family: 'Helvetica', Helvetica, sans-serif;
    font-size: 0.9em;
    background-color: theme('colors.grey-darker');
    padding: 2% 0 2% 1.5%;
    color: theme('colors.grey-faded');
}

.required-field {
    color: rgb(190, 21, 21);
}


/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 576px) {
    input {
        /* margin-top: 5%; */
        width: 104%;
    }

    textarea {
        width: 104%;
    }

    select {
        width: 95%;
    }

    label {
        line-height: 30px;
        /* width: 96%; */
    }
}
