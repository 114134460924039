/* oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

DESKTOP VIEW

oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo */

/* HEADER */
.flex-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.header-container {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    /* min-height: 5vh; */

    z-index: 40;
}

.header-container-fixed {
    position: relative; /* fixed */
    top: 0%;
    left: 0;
    width: 100%;
    min-height: 150px;
    
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    background-color: theme('colors.grey');
    z-index: 10;
}

.header-container-border {
    /* border-bottom: 1px dashed #59595994; */
    z-index: 30;
    width: 436%;
}

.nav-links {
    position: relative;
    margin-top: 2%;
    right: 30%;
    display: flex;
    list-style-type: none;
    white-space: nowrap;
    gap: 3.5%;
    z-index: 40;
}

.nav-links-fixed {
    position: absolute; /* fixed */
    top: 12%; /* temporary, for when nav links arent fixed */
    right: 9%;
    display: flex;
    gap: 7.2%;
    list-style-type: none;
    white-space: nowrap;
    z-index: 40;
}


.nav-links a {
    text-decoration: none;
    margin-left: 10px;
    padding: 2%;
    font-size: 1em;
    text-decoration-color: transparent;
    transition: text-decoration 0.2s ease;
}

.nav-links a:hover {
    cursor: crosshair;
    text-decoration: 1px dashed underline;
    text-decoration-color: black;
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* MOBILE MENU */
  
/* TODO: move this to somewhere that makes sense */
.menu-links a {
    color: #333;
    text-decoration: none;
    padding: 10px;
    transition: 0.3s;
    
}



/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* BUTTONS */
.btn-green {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.5rem;
    padding: 3.5% 5% 3.5% 5%;
    background-color: theme('colors.green-light');
    font-size: 0.93em;
    border: solid 0.5px theme('colors.grey-faded');
    border-radius: 3px;
    font-family: 'Helvetica', Helvetica, sans-serif;
    transition: 0.15s;
    min-width: 13%;
}

/* Specific style for email sign up button */
.btn-green.sign-up-form {
    padding: 0 1.5rem 0 1.5rem;
    height: 2rem;
    top: 0.15rem;
    position: relative;
    min-width: 100%;
}

.btn-green:hover {
    background-color: theme('colors.grey-darkest');
    color: theme('colors.grey-light');
    border: solid 0.5px theme('colors.grey-light');
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* FONTS */

@font-face {
    font-family: 'LoRes_Regular';
    src:url("./fonts/lores12ot-regular-webfont.woff2") format('woff2'),
        url("./fonts/lores12ot-regular-webfont.woff") format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CartographCF_Light';
    src:url("./fonts/CartographCF-Light.woff2") format("woff2");
        url:("./fonts/CartographCF-Light.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src:url('/src/fonts/helvetica.woff2') format('woff2'),
        url('/src/fonts/helvetica.woff') format('woff');
}

@font-face {
    font-family: 'HelveticaBold';
    src:url('/src/fonts/helvetica-bold.woff2') format('woff2'),
        url('/src/fonts/helvetica-bold.woff') format('woff');
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* HEADINGS */

h1.homepage-heading {
    font-family: 'LoRes_Regular';
    font-size: 2.05em;
    position: absolute;
    left: 14%;
    margin-top: 0%;
    width: 100%;
  }

h1 a:hover {
    text-decoration: none;
}

h2.subheading {
    text-align: left;
    font-family: theme('fontFamily.mono');
    position: relative;
    line-height: 2.1em;
    font-size: 1.2rem;
    font-weight: 600;
}

h2 .expand-text {
    font-size: 1em;
    padding-left: 3px;
    font-weight: 600;
    color: #585151;
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* BODY TEXT */        
.content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 8% 20% 5% 20%;
    padding-bottom: 3%;
    min-width: 500px;
}    

body {
    background-color: #DADADA;
    font-family: 'Helvetica', Helvetica, sans-serif;

    font-size: 1rem;
    line-height: 1.5rem;

    margin: 15% 26% 5% 29%;
    text-align: left;

    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.home-text {
    padding-bottom: 1rem;
}

.claim-summary {
    min-height: 100px; /* prevents it from being squashed when window is pulled up */
}


/* Expandable text */
details article {
    opacity: 0;
}
  
details[open] article {
    animation: fadeIn 0.3s linear forwards;
    cursor: default;
}

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    } 

details[open] .expand-text::after {
    content: '—';
    font-size: 0.7em;
}

details { 
    display: inline;
    cursor: crosshair;
    /* margin-bottom: 8%; */
}

details[open] {
    cursor: n-resize;
    margin-bottom: 7%;
}

details > summary {
    list-style: none;
}

details > summary::-webkit-details-marker {
    display: none;
}

details[open] .expand-text::after {
    content: '—';
    font-size: 0.7em;
}

.expand-text {
    font-size: 1.5em;
    padding-left: 3px;
    font-weight: 600;
    color: #585151;
}

.expand-text::after {
    content: "+";
    font-size: 1.1em;
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* LINKS */
a {
    color: black;
    text-decoration: none;
    position: relative;
    z-index: 30;
}

/* Icon that shows link opens in external window */
a[target="_blank"]::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
    white-space: nowrap;
    opacity: 0;
}

a[target="_blank"]:hover::after {
    opacity: 1;
    display: inline;
}

a:hover {
    cursor: crosshair;
    text-decoration: 1px dashed underline;
}

a.active {
    text-decoration: 1px dashed underline !important;
  }

.claim-link {
    margin-bottom: 1.2rem;
}

.claim-link::before {
    content: '';
    background: url('/public/img/arrow-red.svg') no-repeat center center;
    background-size: contain;
    display: inline-block;
    width: 0.6rem;
    height: 0.8rem;
    vertical-align: -5%;
    margin-right: 2%;
}

.claim-coming-soon {
    text-decoration: none;
    color: rgb(94, 94, 94);
    margin-bottom: 1.2rem;
}

.claims-list {
    margin-top: 30px;
}

::selection {
    background: rgba(176, 245, 85, 0.424)
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* COLUMN LAYOUT */

.columns-container {
    margin-top: 6%;

    display: flex;
    justify-content: space-between;

    gap: 100px;
    width: 85%;

    position: relative;
}

.column {
    width: 50%;
    box-sizing: border-box;
}


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* FOOTER */

.footer-container {
    width: 96%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 5%;
}


/* oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

SEMI TABLET VIEW

oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo */
@media only screen and (min-width: 577px) and (max-width: 1023px) {
    .hide-on-tablet {
        display: none;
    }
}


/* oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

TABLET VIEW

oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo */

/* Tablets and small desktops */
@media only screen and (min-width: 577px) and (max-width: 767px) {
    /* Styles for screens between 577px and 767px wide (typically tablets) */

    .btn-green {
        margin-bottom: 10%;
        margin-left: 0;
        padding: 6% 8% 6% 8%;
    }
    
    /* HEADER */
        .header-container-fixed {
            margin-top: -4%;
        }

        .content-container {
            position: relative;
            margin: 5% 20% 0 20%;
            padding-bottom: 3%;
            min-width: 450px;
            left: -4%;
        }
            
        .nav-links-fixed {
            /* right: 17%; */
            /* gap: 5%; */
            display: none;
        }

        /* BODY */
        /* Claim summary */
        .claim-summary {
            width: 70%;
            margin-top: 5%;
        }

        /* hides 1-column view on desktop */
        .columns-container {
            position: relative;
            left: 0%;
            top: 15%;
            display: flex;
            justify-content: space-between;
            gap: 50px;
            width: 90%;
        }
    
        .column {
            width: 50%;
        }

    
        /* MENU */
        /* Menu closed */
        .menu-toggle {
            padding-right: 5px;
            margin-left: 93%;
            margin-top: 53px;
            margin-left: 10px;
        }
    
        .bar {
            width: 0.4rem;
            height: 0.5rem;
            background-color: #060606;
            margin: 8px 0;
            transition: 0.4s;
            
        }
    
        /* Menu open */
        .arrow-menu {
            font-size: 3em;
            margin-bottom: 2.5%;
        }
    
        .menu-links {
            font-size: 1.2em;
            text-align: right;
            margin-right: 1.5rem;
            margin-top: 5%;
    
            display: flex;
            flex-direction: column;
        }
    
        /* space to divine up menu links */
        .break {
            margin-bottom: 5%
        }    
    
    
        /* FOOTER */
        .footer-container {
            display: none;
            }
    }
    
/* @media only screen and (min-width: 576px) {
} */


/* oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo

MOBILE VIEW

oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo */
@media only screen and (max-width: 576px) {

    /* BUTTONS */
    .btn-green {
        margin-bottom: 10%;
        margin-left: 0;
        padding: 5% 8% 5% 8%;
    }

    /* BODY TEXT */
    .content-container {
        margin: 10% 0 15% 0;
        min-width: 300px;
        left: 0;
    }

    body {
        font-size: 0.95em;
    }


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    /* LINKS */
    /* for links next to Archive links */
    a[target="_blank"]::after {
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
        margin: 0 3px 0 12px;
        white-space: nowrap;
        opacity: 0;
    }

    .claim-link {
        margin-bottom: 5%;
    }


    details > summary {
        padding-bottom: 4%;
    }
    
    details.expandable-text-homepage {
        margin-top: 0%;
    }

    details.expandable-text-homepage > summary {
        padding-bottom: 0%;
    }

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    /* HEADER */
    .header-container {
        top: 0;
        left: 0;
        position: fixed;
        background: theme('colors.grey');
        z-index: 50;
        width: 100%;
        /* padding-top: 0px; */
        /* padding-bottom: 0px; */
        /* min-height: 5vh; */
    }

    .header-container-fixed {
        border-bottom: 1px dashed theme('colors.grey-faded');
        min-height: 10%;
        position: sticky;
        top: 0;
        width: 100%;
    }


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    /* HEADINGS */
    h2.subheading {
        font-size: 1.1em;
        margin-top: 2%;
    }

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    /* MENU */
    .menu-toggle {
          cursor: crosshair;
          display: flex;
          flex-direction: column;
          padding: 0.7em; /* Increase clickable area */
        }

    .bar {
        width: 0.3rem;
        height: 0.35rem;
        background-color: #060606;
        margin: 3px 0;
        transition: 0.4s;
      }


    .arrow-menu {
        font-size: 3em;
        margin-bottom: 15px;
    }

    .menu-links {
        display: flex;
        flex-direction: column;
        position: absolute;

        top: 3%;
        right: 5%;

        font-family: 'Helvetica', Helvetica, sans-serif;
        font-size: 1.1em;
        line-height: 1.5em;
        text-align: right;
    }

    .break {
        margin-bottom: 20%;
    }


/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    /* FOOTER */
    .footer {
        display: none;
    }

}


/* Medium-sized devices */
/* Styles for screens between 768px and 991px wide (small desktops) */
/* @media only screen and (min-width: 768px) and (max-width: 991px) {

} */

/* Large desktops */
/* Styles for screens between 992px and 1199px wide (medium desktops) */
/* @media only screen and (min-width: 992px) and (max-width: 1199px) {

} */

/* Extra-large desktops */
/* Styles for screens wider than 1199px (large desktops) */
/* @media only screen and (min-width: 1200px) {

} */
